import React from 'react'

import Sidebar from '../components/sidebar';

export default ({ audioUrl, opportunities }) => (
  <div className="section-wrapper connect">
    <Sidebar
      audioUrl={audioUrl}
      iconName="tree"
    />

    {opportunities.map(({ body, linkText, linkUrl, title }, index) => (
      <div key={`opportunity-${index}`}>
        <h1>{title}</h1>
        <p>{body} <a href={linkUrl} target="_blank" rel="noopener noreferrer">{linkText}</a>.</p>
      </div>
    ))}

    <div className="footer">
      <p>
        Copyright 2020 <a href="https://www.allsoulsseattle.org" target="_blank" rel="noopener noreferrer">All Souls Church of Seattle</a>
        <br />
        Sources: The Book of Common Prayer / ESV Bible / NIV Bible
      </p>
    </div>
  </div>
);
