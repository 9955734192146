import React from 'react'

import Sidebar from '../components/sidebar';

export default ({ audioUrl, readerName }) => (
  <div className="section-wrapper">
    <Sidebar
      audioUrl={audioUrl}
      iconName="giving"
      readerName={readerName}
      title="Giving"
    />

    <h1>Freely you have received; freely give.<br /><i>(Matthew 10:8)</i></h1>

    <p>All we have is given to us by God. Our offerings express this joyful dependence as well as our commitment to join God in his work of renewal. He who met our most dire need through Christ will continue to provide for every need we have— so, whether we have much or little, God calls us to treasure and trust Him above all.</p>

    <p>All gifts go toward ministry initiatives of All Souls and relief of those in need.</p>

    <p><a href="https://www.allsoulsseattle.org/give" target="_blank" rel="noopener noreferrer">Click here to give.</a></p>

    <hr />
  </div>
);
