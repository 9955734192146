import React from 'react'

import Hymn from '../components/hymn';
import Sidebar from '../components/sidebar';
import { getSectionName } from '../helpers';

export default ({ audioUrl, contents, readerName, type, ...hymnProps }) => (
  <div className="section-wrapper">
    <Sidebar
      audioUrl={audioUrl}
      iconName="callToWorship"
      readerName={readerName}
      title={getSectionName({ type: 'callToWorship' })}
    />

    <Hymn {...hymnProps} />

    <hr />

    <div dangerouslySetInnerHTML={{__html: contents}} />

    <hr />
  </div>
);
