import React from 'react';

const copy = 'During the reflection time, children are invited to engage in a special time of reading and song so they can learn and discover the gospel in a language and style more suitable to them.';

const Link = ({ label, href }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">{label}</a>
);

const Wrapper = ({ children }) => (
  <div><p><i>{children}</i></p></div>
);

export default ({ kidsVideoEmbedUrl, kidsVideoEmbedUrl2 }) => {
  if (kidsVideoEmbedUrl && kidsVideoEmbedUrl2) {
    return (
      <Wrapper>
        {copy}
        <br />
        <Link href={kidsVideoEmbedUrl} label="Watch the video for 2-5 year-olds" />
        <br />
        <Link href={kidsVideoEmbedUrl2} label="Watch the video for 1st-3rd grade" />
      </Wrapper>
    );
  } else if (kidsVideoEmbedUrl || kidsVideoEmbedUrl2) {
    return (
      <Wrapper>
        {copy} <Link href={kidsVideoEmbedUrl || kidsVideoEmbedUrl2} label="Click here for the video." />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        All Souls Kids is taking a break this week and will be back next Sunday! In the meantime, feel free to play back <Link href="https://vimeo.com/user/114483628/folder/2192830" label="one of our previous weeks' videos." />
      </Wrapper>
    );
  }
};
