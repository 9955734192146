import React from 'react'

import Hymn from '../components/hymn';
import Sidebar from '../components/sidebar';

export default ({ audioUrl, ...hymnProps }) => (
  <div className="section-wrapper">
    <Sidebar audioUrl={audioUrl} iconName="hymn" title="Hymn" />

    <Hymn {...hymnProps } />
  </div>
);
