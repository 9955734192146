import React, { useEffect } from "react"

import '../styles/main.scss';
import Head from '../components/head';
import Loader from '../components/loader';
import { sectionComponents } from '../helpers';

export default ({ pageContext }) => {
  useEffect(() => {
    // Ensure that page is at top on load
    // otherwise loader is very strange
    window.scrollTo(0,0);
  }, []);

  const { service, scriptures } = pageContext;
  const { audioUrl, sections, ...serviceProps } = service;
  sections.unshift({
    audioUrl,
    name: 'Welcome',
    type: 'welcome',
    ...serviceProps,
  });

  if (!pageContext.service) {
    console.trace();
    return;
  }

  return (
    <div>
      <Head />
      <Loader />

      {sections.map(({ type, ...sectionProps }, index) => {
        const SectionComponent = sectionComponents[type];

        return (
          <div className={`section${sectionProps.inverted ? ' inverted' : ''}`} key={`section-${index}`}>
            <SectionComponent
              {...sectionProps}
              {...serviceProps}
              scriptures={scriptures}
            />
          </div>
        );
      })}

      <img alt="Birds flying over Westcrest Park" className="end-background" src="/background.png" />
    </div>
  );
};
