import React from 'react'

export default ({ reference, scriptures, text, translation }) => (
  <React.Fragment>
    <h3>{reference} ({ translation })</h3>
    {!text && scriptures[reference] && (
      <div dangerouslySetInnerHTML={{__html: scriptures[reference]}} />
    )}
    {text && (
      <div dangerouslySetInnerHTML={{__html: text}} />
    )}
  </React.Fragment>
);
