import React from "react"
import { Helmet } from 'react-helmet';

export default () => {
  return (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-163382775-3"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-163382775-3');
        `}
      </script>
      <title>All Souls at Home</title>
      <meta property="og:title" content="All Souls at Home" />
      <meta property="og:image" content="https://athome.allsoulsseattle.org/share.jpg" />
    </Helmet>
  );
};
