import React from 'react'

import Header from '../components/header';

export default ({ coverUrl, quoteAttribution, quoteText, ...headerProps }) => (
  <div className="section-wrapper welcome">
    <Header {...headerProps} />

    <img alt="Welcome to All Souls at Home" className="welcome-image" src={`https://www.allsoulsseattle.org${coverUrl}`} />
    <p className="welcome-quote" dangerouslySetInnerHTML={{__html: quoteText}} />
    <p className="welcome-quote-attribution">{quoteAttribution}</p>
  </div>
);
