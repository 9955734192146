import React from 'react'

import Video from './video';

export default ({ lyrics, musicCredit, name, sheetMusicUrl, videoEmbedUrl, wordsCredit }) => (
  <React.Fragment>
    <Video videoEmbedUrl={videoEmbedUrl} />

    <div className="hymn-details">
      <div>
        <h3 className="hymn-details--name">"{name}"</h3>
        {wordsCredit && (
          <p className="hymn-details--words-credit">words: {wordsCredit}</p>
        )}
        {musicCredit && (
          <p className="hymn-details--music-credit">music: {musicCredit}</p>
        )}
      </div>
      {sheetMusicUrl && (
        <a
          className="hymn-details--link"
          href={sheetMusicUrl}
          rel="noopener noreferrer"
          target="_blank"
        >download sheet music</a>
      )}
    </div>

    {lyrics && (<div dangerouslySetInnerHTML={{__html: lyrics}} />)}
  </React.Fragment>
);

