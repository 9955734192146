import React from 'react'

import Sidebar from '../components/sidebar';
import Video from '../components/video';
import { getSectionName } from '../helpers';

export default ({ audioUrl, contents, inverted, liturgyType, readerName, videoEmbedUrl }) => (
  <div className="section-wrapper">
    <Sidebar
      audioUrl={audioUrl}
      inverted={inverted}
      iconName={liturgyType}
      readerName={readerName}
      title={getSectionName({ liturgyType })}
    />

    {videoEmbedUrl && (
      <Video videoEmbedUrl={videoEmbedUrl} />
    )}

    <div dangerouslySetInnerHTML={{__html: contents}} />

    {!inverted && <hr />}
  </div>
);
