import dayjs from 'dayjs';
import React from "react"

export default ({ audioUrl, date, title }) => (
  <div className="service-title">
    <div>
      <h3 className="service-title--title">{title}</h3>
      <h3 className="service-title--date">{dayjs(date).format('D MMMM, YYYY')}</h3>
    </div>

    <div className="service-title--links">
      {dayjs(date).isAfter(dayjs('2020-07-10')) && (
        <a href="/ASK_doodlepage.pdf" target="_blank" rel="noopener noreferrer">
          <h3 className="service-title--kids">Kids' Guide</h3>
          <img alt="click for kids worksheet" className="nav-img" src="/crayon.png" />
        </a>
      )}
      <a href={audioUrl} target="_blank" rel="noopener noreferrer">
        <h3 className="service-title--podcast">Full Audio</h3>
        <img alt="click to listen" className="nav-img" src="/podcast.png" />
      </a>
      <a className="logo" href="https://www.allsoulsseattle.org" target="_blank" rel="noopener noreferrer">
        <img alt="All Souls" src="/logo.png" />
      </a>
    </div>
  </div>
);
