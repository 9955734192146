import cx from 'classnames';
import React, { useEffect, useState } from 'react'

import Audio from './audio';
import Carat from '../svg/carat.svg';
import { getLinerNotes } from '../helpers';

export default ({ audioUrl, inverted, iconName, readerName, title }) => {
  // This triggers an opacity change
  const [linerOpen, setLinerOpen] = useState(false);

  // This triggers a display change
  const [showLiner, setShowLiner] = useState(false);

  // All of this nonsense allows us to fade the notes in and out
  // while also setting them to `display: none` when they're invisible
  // so that they don't take up vertical space. That means the icon sticky
  // effect works nicely. :)
  useEffect(() => {
    if (!linerOpen) {
      const timeout = setTimeout(() => setShowLiner(false), 500);
      return () => clearTimeout(timeout);
    }
  }, [linerOpen]);

  const onClickToggle = () => {
    if (!linerOpen) {
      setShowLiner(!linerOpen);
    }

    setImmediate(() => setLinerOpen(!linerOpen));
  };

  const notes = getLinerNotes(iconName);

  return (
    <div className="section-sidebar">
      <img alt={`Icon for ${title}`} src={`/icons/${iconName}${inverted ? '-inverted' : ''}.png`} />
      {title && <p className="title">{title}</p>}
      {audioUrl && <Audio audioUrl={audioUrl} inverted={inverted} readerName={readerName} />}
      {notes && (
        <React.Fragment>
          <button onClick={onClickToggle} title="Click to reveal liner notes">
            <Carat className={cx('liner-toggle', { open: linerOpen })} />
          </button>
          <p className={cx('liner-notes', { open: linerOpen, hide: !showLiner })}>{notes}</p>
        </React.Fragment>
      )}
    </div>
  );
};
