import React, { useEffect, useRef, useState } from 'react'

import PauseIcon from '../svg/pause.svg';
import PlayIcon from '../svg/play.svg';

export default ({ audioUrl, inverted, readerName }) => {
  const audioRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  // Reset when audio finishes
  useEffect(() => {
    const { current } = audioRef;

    const handleEnded = () => {
      current.currentTime = 0;
      setIsPlaying(false);
    };

    current.addEventListener('ended', handleEnded);

    return () => current.removeEventListener('ended', handleEnded);
  });

  // User controls
  const toggle = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const IconSVG = isPlaying ? PauseIcon : PlayIcon;

  return (
    <div className="audio">
      <audio ref={audioRef} src={audioUrl} />
      <button
        id="play"
        onClick={toggle}
        title="Click to play the audio for this section"
      >
        <IconSVG />
      </button>
      <label>{readerName ? `Play reading by ${readerName}` : 'Play audio'}</label>
    </div>
  );
};
